/* ------------------------------------------- */
/* Global Styles
/* ------------------------------------------- */

@viewport {
    orientation: portrait;
}

body {
  overflow: hidden;
  background-color: #000;
}

a {
  font-size: 10pt;
  color: #808080;
  text-decoration: underline;

  &:focus {
    outline: none;
  }
}

h1 {
  font-family: 'Cinzel', Arial, sans-serif;
  color: #ffffff;
  font-size: 22pt;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 0px;

  @media(max-width: 768px) {
    margin-top: 10px;
  }
}

h2 {
  font-family: 'Cinzel', Arial, sans-serif;
  color: #bce7fa;
  font-size: 24pt;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 25pt;
  text-align: left;
  margin-bottom: 2px;

  .second-line-desktop {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14pt;
    font-weight: normal;
    text-transform: none;
    line-height: 14pt;
  }
}

@media(max-width: 768px) {
  .first-line-mobile {
    font-size: 26pt;
  }

}

h3 {
  font-family: 'Cinzel', Arial, sans-serif;
  color: #bce7fa;
  font-size: 16pt;
  font-weight: normal;
  text-transform: uppercase;
}

p {
  font-family: 'Cinzel', Arial, sans-serif;
  color: #adc9ca;
  font-size: 14pt;
  font-weight: normal;

  span {
    color: #ffffff;
    font-weight: bold;
  }
}

.rwd-break {
  @media(min-width: 768px) {
    display: none;
  }
}

/* ------------------------------------------- */
/* Main Wrapper
/* ------------------------------------------- */

.background-wrapper {
  height: 100vh;
  /* padding-top: 10px; */
  background: #000 url('../img/bg-desktop.jpg') no-repeat fixed left;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  @media(max-width: 768px) {
    background: #000 url('../img/bg-desktop-dark.jpg') no-repeat fixed center;
    background-size: cover;
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    background: #000 url('../img/bg-desktop-dark.jpg') no-repeat fixed center;
    background-size: cover;
  }
}

.main-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
}

.main-vertical-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  height: 100vh;

  /*@media(max-width: 768px) {
    display: block;
  }*/
}

.vertical-wrapper {
  // resize: vertical;
  overflow: hidden;
  height: 95vh;

  @media(max-width: 768px) {
    height: 95vh;
  }

  @media(min-width: 992px){
    height: 90vh;
  }

  @media(min-height: 800px) {
    height: 85vh;
  }
}

/* ------------------------------------------- */
/* Content Column
/* ------------------------------------------- */

.home-header {
  @media(max-width: 320px) {
    padding: 0px 40px;
  }
}

.home-container {
  .btn-default-custom {
    margin: 15px auto;
    @media(max-width: 768px) {
      margin-top: 10px;
      padding: 17px 25px 7px 25px;
      font-size: 26pt;
      line-height: 24pt;
    }

    @media(max-width: 320px) {
      margin-top: 15px;
      padding: 17px 25px 7px 25px;
      font-size: 21pt;
      line-height: 24pt;
    }
  }
}

.lang-column {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.first-lang-column {
  ul {
    padding-left: 0px;
    list-style-type: none;

    li {
      padding-top: 5px;
      display: inline-block;

      a {
        display: inline-block;
        font-size: 8pt;
        color: #808080;
      }

      img {
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.second-lang-column {

  .share-list {
    .share-cell {
      display: block;
      font-size: 14pt;
      font-family: 'Cinzel', Arial, sans-serif;
      color: #bce7fa;


      a {
        vertical-align: middle;
        padding: 0px 10px;

        img {
          transition: all ease-in-out .1s;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    li {
      padding: 5px 5px;

      a {
        display: inline-block;
        padding-right: 0px !important;
      }
    }
  }
  ul {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 5px;
  }

  ul li {
    display: inline-block;
    padding: 5px 15px;
  }

  ul li:first-child a {
    font-family: 'Cinzel', Arial, sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    text-decoration: none;
    padding-right: 30px;
  }

  .tickets-cell {
    font-family: 'Cinzel', Arial, sans-serif;
    color: #bce7fa;
    font-size: 12pt;
    text-transform: uppercase;
    text-decoration: none;
    padding-right: 10px;
  }

  .tickets-cell a {
    font-family: 'Cinzel', Arial, sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    text-decoration: none;
    padding-right: 10px;
  }

  ul li a {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 11pt;
    color: #bce7fa;
  }

  @media(max-width: 768px) {
    text-align: center;
     ul li {
      display: inline-block;
      padding: 5px 10px;
    }

    ul li a {
      font-size: 10pt;
      color: #808080;
    }
  }

  @media(max-witdh: 414px){
    text-align: center;
  }

  @media(max-width: 380px) {
    text-align: center;
    ul li {
      display: inline-block;
      padding: 5px 7px;
    }

    ul li a {
      font-size: 9pt;
    }
  }

  @media(max-width: 320px) {
    text-align: center;
    ul li {
      display: inline-block;
      padding: 5px 6px;
    }

    ul li a {
      font-size: 7.5pt;
    }
  }
}

.mobile-official-site {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 10px;

  a {
    font-family: 'Cinzel', Arial, sans-serif;
    font-size: 14pt;
    text-transform: uppercase;
    text-decoration: none;
  }

  @media(max-width: 320px) {
    list-style-type: none;
    padding-left: 0px;
    margin-top: 10px;

    a {
      font-size: 12pt;
    }
  }

  .mobile-share-cell {
    margin-bottom: 10px;

    a {
      font-family: 'Trajan pro' ,"Times New Roman", Times, serif!important;
      font-size: 16pt !important;
      color: #bce7fa !important;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
}

.mobile-tickets a {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #808080 !important;
}

.main-logo {
  margin: 40px auto 20px auto;

  @media(max-width: 768px) {
    margin: 10px auto 20px auto;
  }

  @media(max-width: 320px) {
    margin: 10px auto 10px auto;
  }
}

.main-record-controls {
  img {
    margin: 10px auto 40px auto;
    display: inline-block;
    vertical-align: top;
    padding-right: 15px;
    padding-top: 10px;

    @media(max-width: 768px) {
      margin: 10px auto 10px auto;
    }
  }

  h1 {
    color: #bce7fa;
    margin-top: 20px;
    font-size: 19pt;

    @media(max-width: 768px) {
      font-size: 17pt;
    }
  }

  h2 {
    display: inline-block;
    vertical-align: middle;
    font-size: 34pt;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 10pt;
    margin-top: 10px;
  }

  span {
    font-family: 'Cinzel', Arial, sans-serif;
    font-size: 12pt;
    line-height: 10pt;
  }
}

.record-controls {
  img {
    display: inline-block;
    vertical-align: middle;
    padding-right: 15px;
  }

  h2 {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  .second-line-mobile {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #bce7fa;
    font-size: 18pt;
    font-weight: normal;
    text-transform: none;
    text-align: center;
  }

  @media(max-width: 768px) {
    margin-top: 15vh;
    padding: 0 30px;

    h2 {
      font-size: 30pt;
      text-align: center;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    margin-top: 30vh;
  }

  @media(max-width: 480px) {
    h2 {
      font-size: 36pt;
      text-align: center;
      margin-top: 20px;
    }

    .first-line-mobile {
      font-size: 30pt;
      line-height: 37pt;
    }
  }

  @media(max-width: 375px) {
    margin-top: 8vh;

    h2 {
      font-size: 29pt;
      margin-top: 20px;
    }

    .first-line-mobile {
      font-size: 25pt;
      line-height: 29pt;
    }

    .second-line-mobile {
      font-size: 17pt;
    }
  }

  @media(max-width: 320px) {
    margin-top: 10vh;

    h2 {
      font-size: 25pt;
      margin-top: 20px;
    }

    .first-line-mobile {
      font-size: 21pt;
      line-height: 31pt;
    }

    .second-line-mobile {
      font-size: 13pt;
    }
  }

  .recorder-link {
    @media(max-width: 768px) {
      border: 2px solid #bce7fa;
      margin-bottom: 15px;
    }
  }

  @media(max-width: 320px) and (max-height: 480px) {
    margin-top: 8vh;
  }

}

.trailer-controls {
  margin-top: 20px;

  img {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
  }

  h3 {
    display: inline-block;
    vertical-align: middle;
  }
}

/* Video trailer page */

.close-button {
  position: absolute;
  top: 15px;
  right: 30px;

  @media(max-width: 768px) {
    position: absolute;
    bottom:10px;
    margin: 0 auto;
    text-align: center;
  }
}

/* ------------------------------------------- */
/* Voice recording page
/* ------------------------------------------- */

.custom-background {
  background: #000 url('../img/record-background.jpg') no-repeat center;
  background-size: cover;
  width: 100%;
  position: absolute;
  z-index: -10;
}

.btn-default-custom {
  padding: 15px 20px 10px 20px;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border-color: #bce7fa;
  font-family: 'Cinzel', Arial, sans-serif;
  font-size: 16pt;
  line-height: 16pt;
  color: #bce7fa;
  text-transform: uppercase;
  text-shadow: none;
}

.audio-recorder {
  /* background: #000 url("../img/soundwave.jpg") no-repeat center; */
  z-index: -1;
  height: 500px;
  width: 100%;
  position: absolute;
  top: -16em;
}

canvas#audioWave {
  z-index: -1;
}

/* ------------------------------------------- */
/* Result page
/* ------------------------------------------- */

.result-container {
  .row > [class*='col-'] {
    display: table-cell;
    vertical-align: middle;
  }

  ul {
    padding-left: 0;
  }

  ul > li {
    display: inline-block;
    padding: 0 5px;
  }

  a img {
    transition: all ease-in-out .1s;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    color: #fff;
    font-size: 12pt;
  }

  img {
    margin: 0 auto;
  }

  .mobile-official-site {
    padding-top: 0px;

    .mobile-share-cell {
      margin-bottom: 0px;
    }
  }

}

.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------------------- */
/* Language choice sidebar
/* ------------------------------------------- */

.share-sidebar, .language-choice-sidebar {
  position: absolute;
  left: 0px;
  width: 20%;
  height: 100vh;
  background: rgba(242, 242, 242, 0.95);
  z-index: 9999;
  padding-top: 7vh;

  ul {
    list-style-type: none;
    text-align: center;
    padding-left: 0px;
  }

  li {
    padding: 5px;
  }

  a {
    font-size: 20pt;
    text-decoration: none;
    color: rgb(51, 51, 51);

    &:hover {
      opacity: 0.8;
    }

    @media(max-width: 320px) {
      font-size: 15pt;
      text-decoration: none;
      color: rgb(51, 51, 51);
    }

    @media(max-width: 375px) {
      font-size: 15.5pt;
    }

    @media(max-width: 420px) {
      font-size: 15.5pt;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 100%;
  }

  @media(max-width: 768px){
    width: 100%;
  }

  @media(max-width: 320px) {
    padding-top: 45px;
  }

  .share-cell {
    font-size: 30pt;
    color: #000000;
    font-family: 'Cinzel', Arial, sans-serif;
    text-align: center;

    img {
      margin: 30px auto 0px auto;
    }
  }
}

.close-button-black {
  position: absolute;
  background: url("../img/close-button-black.png") no-repeat right;
  height: 30px;
  width: 30px;
  right: 15px;
  top: 8px;
  float: right;
}

.close-button-black-lg {
  position: absolute;
  background: url("../img/close-button-black-lg.png") no-repeat right;
  height: 30px;
  width: 30px;
  right: 0;
  left: 0;
  bottom: 50px;
  margin: 0 auto;

  @media(max-width: 375px) {
    bottom: 30px;
  }
}

.social-column {
  ul {
    list-style-type: none;
    padding-left: 0px;
    padding-top: 10px;
  }

  ul>li {
    display: inline-block;
    padding: 0px 10px;
  }

  @media(max-width: 768px) {
    p {
      font-size: 12pt;
      margin-bottom: 2px;
      line-height: 18pt;
    }
  }
}

.social-row .btn-default-custom {
  margin-top: 5px;

  @media(min-width: 768px){
    margin-top: 30px;
  }
}

/* ------------------------------------------- */
/* Progress bar button
/* ------------------------------------------- */

*, *:after, *::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* ------------------------------------------- */
/* General styles for button
/* ------------------------------------------- */

.progress-button {
  position: relative;
  display: inline-block;
  padding: 15px 20px 10px 20px;
  outline: none;
  border: 1px solid #bce7fa;
  background: transparent;
  color: #bce7fa;
  text-transform: uppercase;
  font-size: 1em;
  font-family: 'Cinzel', Arial, sans-serif;
  font-size: 16pt;
  line-height: 16pt;
  text-shadow: none;
}

.progress-button[disabled],
.progress-button[disabled].state-loading {
  cursor: default;
}

.progress-button .content {
  position: relative;
  display: block;
}

.progress-button .content::before,
.progress-button .content::after  {
  position: absolute;
  right: 20px;
  color: #bce7fa;
  /* font-family: "icomoon"; */
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;
}

.progress-button .content::before {
  content: "\e600"; /* Checkmark for success */
}

.progress-button .content::after {
  content: "\e601"; /* Cross for error */
}

.progress-button.state-success .content::before,
.progress-button.state-error .content::after {
  opacity: 1;
}

.notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.progress-button .progress {
  background: #3A3A3A;
}

.progress-button .progress-inner {
  position: absolute;
  left: 0;
  background: #3A3A3A;
}

.progress-button[data-horizontal] .progress-inner {
  top: 0;
  width: 0;
  height: 100%;
  -webkit-transition: width 0.3s, opacity 0.3s;
  transition: width 0.3s, opacity 0.3s;
}

.progress-button[data-vertical] .progress-inner {
  bottom: 0;
  width: 100%;
  height: 0;
  -webkit-transition: height 0.3s, opacity 0.3s;
  transition: height 0.3s, opacity 0.3s;
}
/* ------------------------------------------- */
/* Fill horizontal styles
/* ------------------------------------------- */

.progress-button[data-style="fill"][data-horizontal] {
  overflow: hidden;
}

.progress-button[data-style="fill"][data-horizontal] .content {
  z-index: 10;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.progress-button[data-style="fill"][data-horizontal] .content::before,
.progress-button[data-style="fill"][data-horizontal] .content::after {
  top: 100%;
  right: auto;
  left: 50%;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.progress-button[data-style="fill"][data-horizontal].state-success .content,
.progress-button[data-style="fill"][data-horizontal].state-error .content {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

div.button-container > .btn {
  display: block;
  position: relative;
  margin: 1em auto;
}

div.button-container > .btn input[type=file] {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
    height: 100%;
}

video {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    width: 60%;
  }
}

.btn.btn-default.btn-default-custom:hover {
  background: transparent;
  color: #bce7fa;
  border-color: #bce7fa;
  outline: none;
}

/* ------------------------------------------- */
/* Credits Container
/* ------------------------------------------- */

.credits-container {
  position: absolute;
  width: 100vw;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 30px 5px;
  color: rgba(255, 255, 255, 0.61);
  font-weight: 200;
  z-index: 999;

  img {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  ul {
    list-style-type: none;
    padding-left: 0px;

    li {
      display: inline-block;
      padding: 5px 15px;

      a {
        text-transform: uppercase;
        text-decoration: none;
        color: #bce7fa;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .credits-close {
    img {
      margin: 30px 0px 30px 0;
    }
  }

  .mobile-badge {
    width: 10%;
  }
}