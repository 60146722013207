@font-face {
  font-family: 'Cinzel';
  src: url('/fonts/Cinzel-Bold-webfont.eot');
  src: url('/fonts/Cinzel-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Cinzel-Bold-webfont.woff') format('woff'),
  url('/fonts/Cinzel-Bold-webfont.ttf') format('truetype'),
  url('/fonts/Cinzel-Bold-webfont.svg#Cinzel-Bold-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cinzel';
  src: url('/fonts/Cinzel-Regular-webfont.eot');
  src: url('/fonts/Cinzel-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Cinzel-Regular-webfont.woff') format('woff'),
  url('/fonts/Cinzel-Regular-webfont.ttf') format('truetype'),
  url('/fonts/Cinzel-Regular-webfont.svg#Cinzel-Regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'Trajan Pro';
//  src: url('/fonts/TrajanPro-Bold.eot');
//  src: url('/fonts/TrajanPro-Bold.eot?#iefix') format('embedded-opentype'),
//    url('/fonts/TrajanPro-Bold.woff2') format('woff2'),
//    url('/fonts/TrajanPro-Bold.woff') format('woff'),
//    url('/fonts/TrajanPro-Bold.ttf') format('truetype'),
//    url('/fonts/TrajanPro-Bold.svg#TrajanPro-Bold') format('svg');
//  font-weight: bold;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Trajan Pro';
//  src: url('/fonts/TrajanPro-Regular.eot');
//  src: url('/fonts/TrajanPro-Regular.eot?#iefix') format('embedded-opentype'),
//    url('/fonts/TrajanPro-Regular.woff2') format('woff2'),
//    url('/fonts/TrajanPro-Regular.woff') format('woff'),
//    url('/fonts/TrajanPro-Regular.ttf') format('truetype'),
//    url('/fonts/TrajanPro-Regular.svg#TrajanPro-Regular') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/HelveticaNeueLTStd-Cn.eot');
  src: url('/fonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HelveticaNeueLTStd-Cn.woff2') format('woff2'),
    url('/fonts/HelveticaNeueLTStd-Cn.woff') format('woff'),
    url('/fonts/HelveticaNeueLTStd-Cn.ttf') format('truetype'),
    url('/fonts/HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/HelveticaNeueLTStd-Lt.eot');
  src: url('/fonts/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
    url('/fonts/HelveticaNeueLTStd-Lt.woff') format('woff'),
    url('/fonts/HelveticaNeueLTStd-Lt.ttf') format('truetype'),
    url('/fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
  font-weight: 300;
  font-style: normal;
}