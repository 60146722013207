.message-body {
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  font-family: 'Helvetica Neue';
  color: #fff;
  background-color: #000;
  padding-top: 15%;

  h4 {
    font-weight: 300;

    a {
      color: #bce7fa;
    }
  }
}

