.button-container button.btn {
  display: none;
}

.button-container.record.state1 button.btn.record_state1 {
  display: block!important;
}

.button-container.record.state0 button.btn.record_state0 {
  display: block!important;
}

.button-container.upload.state0 button.btn.record_state0 {
  display: none!important;
}

.button-container.upload.state0 button.btn.upload_state0 {
  display: block!important;
}

.button-container.state2 button.btn.state2 {
  display: block!important;
}
