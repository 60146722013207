.home-header p {
  @include fadeIn (
    $duration: .9s,
    $delay: .9s
  );
}

.home-header img {
  @include fadeIn(
    $duration: .9s,
    $delay: 1.4s
  );
}

.record-controls {
  @include fadeIn(
    $duration: .9s,
    $delay: 1.9s
    );
}

.home-container button {
  @include fadeIn(
    $duration: .9s,
    $delay: 2.4s
  );
}

.home-container .theaters-header {
  @include fadeIn(
    $duration: .9s,
    $delay: 2.9s
  );
}

.mobile-official-site {
  @include fadeIn(
    $duration: .9s,
    $delay: 2.9s
  );
}

.background-wrapper {
  @include fadeInLeft(
    $delay: .2s
  );

  // animation: shake 0.32s cubic-bezier(.36,.07,.19,.97) both;
  // -webkit-animation-iteration-count: 3; /* Chrome, Safari, Opera */
  // vanimation-iteration-count: 3;

  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

}

.record-header {
  img {
    @include fadeIn(
      $duration: .9s,
      $delay: .9s
    );
  }

  h1 {
    @include fadeIn(
      $duration: .9s,
      $delay: 1.4s
    );
  }
}

.record-timer {
  @include fadeIn(
    $duration: .9s,
    $delay: 1.9s
  );
}

.button-container.state0 button {
  @include fadeIn(
    $duration: .9s,
    $delay: 2.4s
  );
}

.video-row {
  @include fadeIn(
    $duration: .9s,
    $delay: .9s
  );
}

.social-row {
  .logo-column {
    @include fadeIn(
            $duration: .4s,
            $delay: 1.4s
    );
  }

  .social-column {
    @include fadeIn(
            $duration: .4s,
            $delay: 1.8s
    );
  }

  .button-column {
    @include fadeIn(
            $duration: .4s,
            $delay: 2.2s
    );
  }
}

.button-container {
  // @include pulse()
}

.main.ng-leave {
  @include fadeOut();
}

.main.ng-enter {
  @include fadeIn();
}

.btn.btn-default.btn-default-custom:hover {
  // @include pulse();
}

/* @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
} */