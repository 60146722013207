body.orientleft .notsupported, body.orientright .notsupported {
  display: block!important;
  background: black;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}

.notsupported {
  padding: 50px;
  text-align: center;

  h1 {
    margin-top: 90px;
  }
}