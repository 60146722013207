.modal-content, .modal-dialog, .modal-header {
  background-color: transparent;
  border: solid 1px #bce7fa;
  color: #fff;
  border-radius: 0;
  border-color: #fff;
  text-align: center;
}

.modal-body, .modal-dialog, .modal-header, .modal-footer {
  border: none;
  color: #fff;
}

.gradient-background {
  width: 85%;
  margin-top: -29px;
  margin-left: auto;
  margin-right: auto;
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 5%, #000000 13%, #000000 87%, rgba(0, 0, 0, 0) 95%); /* FF3.6-15 */
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 5%,#000000 13%,#000000 87%,rgba(0, 0, 0, 0) 95%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 5%,#000000 13%,#000000 87%,rgba(0, 0, 0, 0) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  //background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, black 10%, black 90%, rgba(255, 255, 255, 0) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, black 10%, black 90%, rgba(255, 255, 255, 0) 100%);

  @media(max-width: 440px){
    width: 90%;
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 20%, #000000 28%, #000000 72%, rgba(0, 0, 0, 0) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 20%,#000000 28%,#000000 72%,rgba(0, 0, 0, 0) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 20%,#000000 28%,#000000 72%,rgba(0, 0, 0, 0) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    //background: linear-gradient(90deg, rgba(255, 255, 255, 0) 15%, black 35%, black 65%, rgba(255, 255, 255, 0) 85%);
  }
}

.modal-header {
   h3 {
    color: #fff;
    font-size: 22pt;
    line-height: 26pt;

    @media(max-width: 320px) {
      font-size: 17pt;
    }
  }
}

.modal-body {
  padding: 15px 45px;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    li {
      display: inline-block;
      padding: 0 10px;
    }
  }

  p {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #ffffff;

    @media(max-width: 320px) {
      font-size: 12pt;
    }
  }
}

@media(min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 10% auto;
  }
}

@media(max-width: 320px) {
  .modal-dialog {
    width: 300px;
    margin: 10% auto;
  }
}

.modal-footer {
  text-align: center;

  button {
    position: absolute;
    padding: 15px 20px 15px 20px;
    z-index: 100;
    background: #000;
    width: 145px;
    left: 0;
    right: 0;
    bottom: -33px;
    margin: 0 auto;
    width: 193px;
    color: #bce7fa;
    font-size: 20pt;
    line-height: 22pt;
    border: 2px solid;
    border-color: #bce7fa;
    transition: all ease-in-out .2s;

    &:hover {
      color: rgba(255, 255, 255, 0.60);
    }
  }
}

.modal-backdrop.in {
  opacity: 0.85;
  filter: alpha(opacity=85);
}